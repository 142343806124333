import { CompanyConfig, flagsForPublishers } from "@metranpage/core-interfaces";

const config: CompanyConfig = {
  deployedUrl: "https://app.editus.com/",
  ga: "G-79QKHNF94B",
  companyUuid: "c11b0320-e9e7-4f18-a90d-d2934db31ff7",
  availableLanguages: [
    { visibleName: "Русский", iconSrc: "/assets/img/flag-ru.svg", value: "ru" },
    { visibleName: "English", iconSrc: "/assets/img/flag-gb.svg", value: "en" },
  ],
  downloadFileTemplate: "{filename}",
  color: "#db1a21",
  socials: [],
  flags: {
    ...flagsForPublishers,
    isCoverEditorEnabled: false,
  },
};
export { config };
